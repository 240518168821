import { createRouter, createWebHistory } from 'vue-router';
// eslint-disable-next-line import/no-cycle
import { useAuthStore } from '@/store/auth/authModule';
// eslint-disable-next-line import/no-cycle
import { useMainStore } from '@/store/index';
import { setCookie, getCookie } from '~utils/cookies';
// eslint-disable-next-line import/no-cycle
import { ConstantParams } from '~store/types';
// eslint-disable-next-line import/no-cycle
import Api from '~utils/Api';
// eslint-disable-next-line import/no-cycle
import routes from './routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const store = useAuthStore();
  const url = window.location;
  await store.getImei();
  if (!from.name) {
    const regex = /\?code=(.*)/gm;
    const regexLK = /h=([^&]+)/;
    const guCode = url.href.match(regex);
    const lkHash = to.fullPath.match(regexLK);
    if (lkHash && lkHash[1] && !store.isAuth) {
      const params = {
        hash: lkHash[1],
        IMEI: store.getIMEI,
      };
      const result = await Api.hashLogin(params);
      if (result) {
        if (result.err_text === 'Ссылка просрочена' || result.err_text.includes('Ссылка недействительна')) {
          useMainStore().showMessage(result?.err_text);
        } else {
          [, store.hash] = lkHash;
          setCookie('id_login', `${result.id_login}`, { secure: true, expires: 1 });
          setCookie('lk_login', `${result.lk_login}`, { secure: true, expires: 1 });
          setCookie('TK', result.TK, { secure: true, expires: 1 });
          const constantParams: ConstantParams = {
            IMEI: store.getIMEI,
            Name_app: 'EcoHospital',
            TK: result.TK,
            id_login: result.id_login,
            lk_login: result.lk_login,
          };
          useMainStore().setConstantParams(constantParams);
          // store.isAuth = true;
          next({ path: '/dashboard' });
        }
      }
    }
    if (guCode && guCode.length && !store.isAuth) {
      try {
        const res = await fetch(`https://esia.medsafe.tech/r/${getCookie('eco-hash')}/${store.getIMEI}${guCode[0]}`);
        if (res.status >= 200 && res.status < 300) {
          const data = await res.json();
          if (data && data.length) {
            if (data[0].TK) {
              setCookie('id_login', `${data[0].id_login}`, { secure: true, expires: 1 });
              setCookie('lk_login', `${data[0].lk_login}`, { secure: true, expires: 1 });
              setCookie('TK', data[0].TK, { secure: true, expires: 1 });
              const constantParams: ConstantParams = {
                IMEI: store.getIMEI,
                Name_app: 'EcoHospital',
                TK: data[0].TK,
                id_login: data[0].id_login,
                lk_login: data[0].lk_login,
              };
              useMainStore().setConstantParams(constantParams);
              store.isAuth = true;
              next({ path: '/dashboard' });
            }
          } else {
            next({ path: '/login' });
            useMainStore().showMessage('Извините, мы Вас не узнали...');
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  }
  const isAuth = store.getIsAuth;
  // if (!isAuth) {
  await useAuthStore().autenticate();
  // }
  const toCompare = store.isAuth;
  // || (getCookie('id_login') && getCookie('TK'));
  if (!toCompare && to.path !== '/login') {
    next({ path: '/login' });
  } else if (toCompare && to.path === '/login') next({ path: '/dashboard' });
  else next();
});

export default router;
