import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "header__link_note" }
const _hoisted_2 = { class: "header__username text-primary" }

import {
  reactive,
  watch,
  computed,
  ref,
  nextTick,
} from 'vue';
import { getNameByMode } from '~utils/stringHelpers';
import { useAuthStore } from '@/store/auth/authModule';
import { useMainStore } from '@/store';
import {
  storeToRefs,
  getActivePinia,
  Pinia,
  Store,
} from 'pinia';

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>;
}
type MenuLink = {
  id: number,
  link: string,
  text: string,
  note?: string,
};


export default /*@__PURE__*/_defineComponent({
  __name: 'AppHeader',
  setup(__props) {

const $ResetPinia = (): void => {
  const pinia = getActivePinia() as ExtendedPinia;

  if (!pinia) {
    throw new Error('There is no stores');
  }

  pinia._s.forEach((store) => {
    store.$reset();
  });
};
const data = reactive({
  seamless: false,
  src: '',
  text: '',
});
const reportDialog = ref();
const store = useAuthStore();
const { userName } = storeToRefs(store);

const visibleUserName = computed(() => {
  if (userName) {
    return getNameByMode(userName.value, 'lastname');
  }
  return '';
});
const avatarInitials = computed(() => {
  if (userName) {
    return getNameByMode(userName.value, 'initials');
  }
  return '';
});

const logout = () => {
  $ResetPinia();
  useAuthStore().logout();
};

const menu: MenuLink[] = [
  // {
  //   id: 1,
  //   link: '/dashboard',
  //   text: 'Главная',
  // },
  // {
  //   id: 2,
  //   link: '/struct',
  //   text: 'API',
  // },
];


return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_header = _resolveComponent("q-header")!

  return (_unref(store).isAuth)
    ? (_openBlock(), _createBlock(_component_q_header, {
        key: 0,
        elevated: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar, { class: "bg-grey-3" }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(menu, (item) => {
                return _createVNode(_component_router_link, {
                  class: "header__link text-accent",
                  key: item.id,
                  to: item.link
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_btn, {
                      flat: "",
                      label: item.text
                    }, null, 8, ["label"]),
                    _createElementVNode("span", _hoisted_1, _toDisplayString(item.note ? [item.note] : ''), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              }), 64)),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_q_avatar, {
                  size: "32px",
                  color: "light-green-6",
                  "text-color": "white",
                  class: "q-mr-xs"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(avatarInitials.value), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", null, _toDisplayString(visibleUserName.value), 1)
              ]),
              _createVNode(_component_q_space),
              _createVNode(_component_q_btn, {
                size: "sm",
                push: "",
                icon: "logout",
                color: "white",
                "text-color": "accent",
                label: "",
                class: "q-ml-md",
                onClick: logout
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})