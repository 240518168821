import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

import { PropType, ref } from 'vue';
import { ActionParams, FieldContent } from '@/components/TableComponent/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomButton',
  props: {
  value: {
    type: Object as PropType<FieldContent>,
    default: () => {},
  },
  actionParams: {
    type: Object as PropType<ActionParams>,
    default: () => {},
  },
},
  emits: ['action'],
  setup(__props, { emit: __emit }) {

const props = __props;

const action = ref(props.value.action);

const emit = __emit;

const clickHandler = () => {
  const params = {
    actionId: action.value.name,
    actionType: action.value.type,
    params: props.actionParams,
  };
  emit('action', params);
};


return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    flat: "",
    color: "accent",
    onClick: _withModifiers(clickHandler, ["stop"])
  }, {
    default: _withCtx(() => [
      (__props.value?.icon)
        ? (_openBlock(), _createBlock(_component_q_icon, {
            key: 0,
            name: __props.value?.icon
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(__props.value?.text), 1)
    ]),
    _: 1
  }))
}
}

})