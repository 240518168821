import { NAME_APP, modules } from '@/constants';
import { defineStore } from 'pinia';
// eslint-disable-next-line import/no-cycle
import router from '@/router/index';
// eslint-disable-next-line import/no-cycle
import Api from '~utils/Api';
import { setCookie, deleteCookie, getCookie } from '~utils/cookies';
// eslint-disable-next-line import/no-cycle
import { ConstantParams } from '../types';
// eslint-disable-next-line import/no-cycle
import { useMainStore } from '../index';
import { User, UserData, PassChanged } from './types';

// const api = new Api();

const DAYS = 1;

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuth: false,
    IMEI: '',
    appHash: '' as string,
    user: {} as User,
    processing: false as boolean,
    needChange: false as boolean,
    hash: '',
  }),

  getters: {
    getIsAuth: (state) => state.isAuth,
    currentUser: (state) => state.user,
    currentUserId: (state) => state.user.id_login,
    currentLKId: (state) => state.user.lk_login,
    userName: (state) => state.user.vrach_fio || state.user.fio || '',
    getAppHash: (state) => state.appHash,
    getIMEI: (state) => state.IMEI,
  },

  actions: {
    async getImei() {
      this.IMEI = await Api.getImei();
    },

    async signIn(login: string, password: string): Promise<void> {
      if (!this.IMEI) {
        await this.getImei();
      }
      const params = {
        login,
        password,
        IMEI: this.IMEI,
        Name_app: NAME_APP,
      };
      try {
        const result = await Api.login(params);
        if (result) {
          this.user = <User><unknown>result;
          console.log(this.user);
          if (this.user?.lk_login && this.user?.TK) {
            const constantParams: ConstantParams = {
              IMEI: this.IMEI,
              Name_app: NAME_APP,
              TK: this.user.TK,
              id_login: this.user.id_login,
              lk_login: this.user.lk_login,
            };
            setCookie('id_login', `${this.user.id_login}`, { secure: true, expires: DAYS });
            if (this.user.lk_login) setCookie('lk_login', `${this.user.lk_login}`, { secure: true, expires: DAYS });
            setCookie('TK', this.user.TK, { secure: true, expires: DAYS });
            useMainStore().setConstantParams(constantParams);
            console.log(getCookie('TK'));
            if (this.user.err_text === 'Открыть интерфейс создания пароля') {
              this.needChange = true;
            }
            this.isAuth = true;
            router.push({ name: 'Dashboard' });
          } else {
            useMainStore().showMessage('Извините, не узнали Вас...');
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async changePassword(password: string, hash = ''): Promise<string | false> {
      const params = {
        login: this.user.lk_login,
        password,
        hash,
        TK: useMainStore().constantParams.TK,
        Name_app: useMainStore().constantParams.Name_app,
        kind: 1,
      };
      try {
        const result = await Api.changePassword(params);
        if (!result.err) {
          return result.err_text;
        }
      } catch (err) {
        console.error(err);
      }
      return false;
    },

    checkAuth() {
      const storedUserData: UserData = {
        id_login: Number(getCookie('id_login')) || 0,
        lk_login: Number(getCookie('lk_login')) || 0,
        TK: getCookie('TK') || '',
      };
      if (storedUserData.lk_login && storedUserData.TK) {
        return storedUserData;
      }
      return false;
    },

    async autenticate(): Promise<void> {
      this.processing = true;
      if (!this.IMEI) {
        // this.IMEI = getCookie('IMEI') || '';
        await this.getImei();
      }
      const userData: UserData | boolean = this.checkAuth();
      if (!userData) {
        this.processing = false;
        return;
      }
      console.log(userData);
      const params = {
        ...userData,
        IMEI: this.IMEI,
        Name_app: NAME_APP,
        Name_event: 'GET:user_data',
        TK: userData.TK,
        id_login: userData.id_login,
        lk_login: userData.lk_login,
      };
      try {
        const result = await Api.get(params);
        if (!result.errorType && result.data) {
          [this.user] = result.data as User[];
          const constantParams: ConstantParams = {
            IMEI: this.IMEI,
            Name_app: NAME_APP,
            TK: userData.TK,
            id_login: this.user.id_login,
            lk_login: this.user.lk_login,
          };
          useMainStore().setConstantParams(constantParams);
          this.isAuth = true;
          if (this.user.err_text === 'Открыть интерфейс создания пароля' && !this.needChange) {
            this.needChange = true;
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.processing = false;
      }
    },

    async logout(): Promise<void> {
      this.isAuth = false;
      this.user = <User><unknown>{};
      deleteCookie('id_login');
      deleteCookie('lk_login');
      deleteCookie('TK');
      router.push({ name: 'Login' });
    },
  },
});
