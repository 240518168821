<template>
  <div class="editable">
    <span class="editable__value" v-show="!isEdit" :style="textWidth">
      {{ editable }}
    </span>
    <q-input
      name="editable"
      outlined
      autofocus
      dense
      :style="fieldWidth"
      v-if="isEdit"
      class="editable__input"
      v-model="editable"
      @keyup.enter="save"
      @focusout="save"
      ref="input"/>
    <q-btn @click.stop="edit" class="editable__button" v-if="!isEdit">
      <q-icon name="edit" />
    </q-btn>
    <q-btn @click.stop="save" class="editable__button" v-if="isEdit">
      <q-icon name="save" />
    </q-btn>
  </div>
</template>

<script setup lang="ts">
import {
  ref, PropType, computed,
} from 'vue';
import { FieldContent, ActionParams } from './types';

const props = defineProps({
  text: {
    type: String as PropType<string>,
    default: () => '',
  },
  width: {
    type: Number as PropType<number>,
    default: () => 202,
  },
  value: {
    type: Object as PropType<FieldContent>,
    default: () => {},
  },
  actionParams: {
    type: Object as PropType<ActionParams>,
    default: () => {},
  },
});

const emit = defineEmits(['action']);

const textWidth = computed(() => `width: ${props.width}px`);
const fieldWidth = computed(() => `min-width: ${props.width}px`);
const editable = ref(props.text);
const isEdit = ref(false);

const edit = () => {
  isEdit.value = true;
};

const save = () => {
  const data = {
    actionId: props.value.action.name,
    actionType: 'event',
    params: { text: editable.value, ...props.actionParams },
  };
  emit('action', data);
  isEdit.value = false;
};

</script>

<style lang="scss" scoped>
  .editable{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    position: relative;
    &__value{
      overflow: hidden;
      margin-right: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &__input{
      margin-right: 10px;
      outline: none;
    }
    &__button{
      padding: 0!important;
      width: 30px!important;
      height: 30px!important;
    }
  }
</style>
