<template>
  <div class="spinner-wrapper">
    <q-spinner-ios color="accent" :size="size" />
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';

export default { name: 'AppLoader' };
</script>

<script setup lang="ts">
defineProps({
  size: {
    type: String as PropType<string>,
    default: '50px',
  },
});
</script>

<style>
.spinner-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
