import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, Suspense as _Suspense, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { PropType } from 'vue';
import AppHeader from '~components/AppHeader.vue';
import AppLoader from '~components/AppLoader.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseLayout',
  props: {
  isLoading: {
    type: Boolean as PropType<boolean>,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page_container = _resolveComponent("q-page-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(AppHeader),
    _createVNode(_component_q_page_container, {
      class: "base-wrapper",
      style: {"padding":"0"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            (_openBlock(), _createBlock(_Suspense, { timeout: "0" }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  key: _ctx.$route.path
                }))
              ]),
              fallback: _withCtx(() => [
                _createVNode(_component_q_inner_loading, {
                  showing: true,
                  label: "Идет загрузка..."
                }, {
                  default: _withCtx(() => [
                    _createVNode(AppLoader)
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1024))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_q_inner_loading, { showing: __props.isLoading }, {
      default: _withCtx(() => [
        _createVNode(AppLoader)
      ]),
      _: 1
    }, 8, ["showing"])
  ], 64))
}
}

})