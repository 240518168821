import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo-gosuslugi-ru.png'


const _hoisted_1 = {
  class: "q-pa-md q-mx-auto",
  style: {"min-width":"350px"}
}
const _hoisted_2 = { class: "flex justify-center" }
const _hoisted_3 = { class: "flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("h4", { class: "text-center" }, "Личный кабинет", -1)),
    _cache[5] || (_cache[5] = _createElementVNode("p", null, "Используйте логин и пароль из памятки ", -1)),
    _createVNode(_component_q_form, {
      onSubmit: _withModifiers(_ctx.onSubmit, ["prevent"]),
      onReset: _ctx.onReset,
      class: "q-gutter-md"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_input, {
          dense: "",
          filled: "",
          modelValue: _ctx.data.login,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.login) = $event)),
          label: "Логин",
          "lazy-rules": "",
          rules: [ val => val && val.length > 0 || 'Обязательное поле']
        }, null, 8, ["modelValue", "rules"]),
        _createVNode(_component_q_input, {
          dense: "",
          filled: "",
          type: _ctx.isPwd ? 'password' : 'text',
          modelValue: _ctx.data.password,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.password) = $event)),
          label: "Пароль",
          "lazy-rules": "",
          class: "q-mt-xs",
          rules: [
        val => val !== null && val !== '' || 'Обязательное поле',
      ]
        }, {
          append: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: _ctx.isPwd ? 'visibility_off' : 'visibility',
              class: "cursor-pointer",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isPwd = !_ctx.isPwd))
            }, null, 8, ["name"])
          ]),
          _: 1
        }, 8, ["type", "modelValue", "rules"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_btn, {
            size: "sm",
            push: "",
            icon: "logout",
            glossy: "",
            color: "white",
            "text-color": "accent",
            label: "Войти",
            type: "submit"
          }),
          _createVNode(_component_q_btn, {
            push: "",
            size: "sm",
            label: "Стереть",
            type: "reset",
            color: "white",
            "text-color": "standard",
            class: "q-ml-sm"
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_q_btn, {
            push: "",
            size: "sm",
            type: "reset",
            color: "white",
            "text-color": "standard",
            href: `https://esia.medsafe.tech/${_ctx.hashLink}`,
            onClick: _ctx.setAppHash
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" Войти через "),
              _createElementVNode("img", {
                alt: "gu-logo",
                width: "100px",
                class: "q-ml-xs",
                src: _imports_0
              }, null, -1)
            ])),
            _: 1
          }, 8, ["href", "onClick"])
        ])
      ]),
      _: 1
    }, 8, ["onSubmit", "onReset"])
  ]))
}