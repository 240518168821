<template>
  <div class="table__header">
    <div class="text-h6">{{structure.title}}</div>
  </div>
  <div class="table__wrapper">
    <q-markup-table dense>
      <thead class="sticky-head" v-if='!structure.hide_head'>
        <tr>
          <th v-if="structure.selection">
            <q-checkbox
              v-if="structure.selection === 'multiple'"
              v-model="data.selectAll"
              @update:model-value="handleSelectAll"
            />
          </th>
          <th
            v-for="(column, index) in visibleColumns"
            :key="index"
            :class="[{ sortable: column.sortable }]"
          >
            <q-icon v-if="column.sorted === 'asc'" name="expand_more"/>
            <q-icon v-if="column.sorted === 'desc'" name="expand_less"/>
            <span class="colname colname_sortable"
              @click="column.sortable ? sort(column) : ''">
              {{column.alias}}
            </span>
            <q-btn
              v-if="column.filterable"
              :class="{active: data.filters.find(
                (filter) => filter.colName === column.name_column)
              }"
              flat
              round
              icon="filter_list">
              <q-menu>
                <ColumnFilter
                  :initial="data.filters.find((filter) => filter.colName === column.name_column)"
                  :multiple="column.filterable === 'multiple'"
                  :colName="column.name_column"
                  :items="setColumnValues(column.name_column)"
                  :label="column.alias"
                  @setFilter="setFilter"
                />
              </q-menu>
            </q-btn>
            <q-btn
              flat
              rounded
              v-if="column.could_hide && structure.expandable_rows"
              @click="column.could_hide = true"
            >
              <q-icon name="visibility_off" />
            </q-btn>
          </th>
        </tr>
      </thead>
      <tbody :style='structure.direction ? "display: flex;" : ""'>
        <template
          v-for="(row) in paginatedItems"
          :key="row[data.field_id]">
          <tr
            :style='row.style_row ? JSON.parse(row.style_row)[0] : ""'
            :class="{ clickable: structure.expandable_rows }"
            @click="expandRow(row[data.field_id])">
            <td v-if="structure.selection">
              <q-checkbox
                :val="row[data.field_id]"
                v-model="data.selectedItems"
                @update:model-value="handleSelect"
              />
            </td>
            <td
              v-for="(_, index) in visibleColumns"
              :key="index"
            >
              <slot>
                <component
                  :is="defineComponentName(visibleColumns[index])"
                  v-bind="defineCellProps(
                    visibleColumns[index], row[visibleColumns[index].name_column]
                  )"
                  :actionParams="row"
                  @action="actionHandler"
                />
              </slot>
            </td>
          </tr>
          <tr v-if="structure.expandable_rows && row[data.field_id] === data.expandedRow">
            <td :colspan="expandableAreaSize">
              <component
                :is="ExpandableContent"
                :data="row"
                :columns="hiddenColumns"
                :additional="structure.expandable_content"
                @unhideColumn="unhideColumn"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </q-markup-table>
  </div>
  <div class="table__footer" v-if="structure.pagination">
    <span class="pagination-description">Показывать по:</span>
    <q-select
      outlined
      dense
      v-model="data.itemsPerPage"
      :options="paginationOptions"
    />
    <q-pagination
      :max="maxPages"
      color="accent"
      direction-links
      boundary-links
      boundary-numbers
      v-model="data.currentPage"
      @update:modelValue="paginate"
    />
  </div>
</template>

<script setup lang="ts">
import {
  PropType, computed, reactive, defineAsyncComponent, defineProps, onBeforeMount,
  onActivated, onDeactivated,
} from 'vue';
import { actionHandler } from '~actions/actionHandler';
import {
  TableColumn, TableRow, TableStructure, Filter,
} from './types';
import SimpleCell from './SimpleCell.vue';
import EditableCell from './EditableCell.vue';
import ExpandableCell from './ExpandableCell.vue';
import CustomButton from './CustomButton.vue';
import ColumnFilter from './ColumnFilter.vue';
import ExpandableContent from './ExpandableContent.vue';
import { convertDataForSorting } from './helpers';
import JsonStringCell from './JsonStringCell.vue';

const cellsTypeMap = {
  expanded: ExpandableCell,
  json: SimpleCell,
  json_string: JsonStringCell,
  custom: null,
  button: CustomButton,
  editable: EditableCell,
  default: SimpleCell,
};

enum SortDirection {
  asc = 1, desc = -1, nd = 0,
}

const paginationOptions: number[] = [5, 10, 20, 50];

const props = defineProps({
  data: {
    type: Array as PropType<TableRow[]>,
    default: () => [],
  },
  structure: {
    type: Object as PropType<TableStructure>,
    default: () => {},
  },
});

const data = reactive({
  items: props.data,
  field_id: props.structure.field_id,
  columns: props.structure.columns,
  direction: SortDirection.nd,
  selectedItems: [] as number[],
  selectAll: false,
  isLoading: false,
  filters: [] as Filter[],
  currentPage: 1,
  expandedRow: null as number | null,
  itemsPerPage: 20,
});

const generateIds = () => {
  if (!props.structure.field_id) {
    data.field_id = 'id';
    data.items.forEach((item, index) => {
      // eslint-disable-next-line no-param-reassign
      item.id = index;
    });
  }
};

onBeforeMount(() => { generateIds(); });

// onDeactivated(() => {
//   console.log(data.selectedItems);
//   localStorage.setItem('selectedItems', JSON.stringify(data.selectedItems));
// });

// onActivated(() => {
//   console.log(data.selectedItems);
//   if (localStorage.getItem('selectedItems')) {
//     data.selectedItems = JSON.parse(localStorage.getItem('selectedItems') || '');
//   }
// });

const defineComponentName = (column: TableColumn) => {
  if (column.type === 'custom' && column.custom_component_name) {
    return defineAsyncComponent(() => import(`@/components/TableComponent/${column.custom_component_name}.vue`));
  }
  return cellsTypeMap[column.content_type];
};

const defineCellProps = (column: TableColumn, content: keyof TableRow) => {
  let text;
  if (column.content_type === 'json_string' && content) {
    text = JSON.parse(content.toString());
    const fields = data.columns.filter((i) => i.name_column.includes(`${column.name_column}.`)) ?? [];
    return {
      ...column,
      fields,
      text,
    };
  }
  if (typeof content === 'number') {
    text = content.toString();
  } else {
    text = content;
  }
  return {
    ...column,
    text,
  };
};

const visibleColumns = computed(() => data.columns
  .filter((el) => el.visible && !el.could_hide));

const hiddenColumns = computed(() => data.columns
  .filter((el) => el.could_hide)
  .map((col) => ({
    name: col.name, readableName: col.name_column,
  })));

const unhideColumn = (name: string) => {
  const column = data.columns.find((col) => col.name === name);
  if (column) {
    column.could_hide = false;
  }
};

const expandableAreaSize = computed(() => {
  const coeff = props.structure.selection ? 1 : 0;
  return visibleColumns.value.length + coeff;
});

const filteredItems = computed((): TableRow[] => {
  let filtered = data.items;
  data.filters.forEach((el) => {
    filtered = filtered.filter((item) => {
      if (typeof el.value === 'string') {
        return el.value === item[el.colName];
      }
      return el.value?.includes(item[el.colName]);
    });
  });
  return filtered;
});

const sort = (column: TableColumn) => {
  switch (data.direction) {
    case SortDirection.nd:
      data.direction = SortDirection.asc;
      break;
    case SortDirection.asc:
      data.direction = SortDirection.desc;
      break;
    case SortDirection.desc:
      data.direction = SortDirection.asc;
      break;
    default:
      data.direction = SortDirection.nd;
  }

  data.items.sort((a, b) => {
    let result = 0;

    const aData: string | number = convertDataForSorting(a[column.name]);
    const bData: string | number = convertDataForSorting(b[column.name]);
    if (aData > bData) {
      result = 1;
    } else if (bData > aData) {
      result = -1;
    }
    return result * data.direction;
  });
  data.columns.forEach((col) => {
    // eslint-disable-next-line no-param-reassign
    col.sorted = undefined;
  });
  // eslint-disable-next-line no-param-reassign
  column.sorted = SortDirection[data.direction];
};

const setColumnValues = (colName: string): string[] | undefined => {
  const values: string[] = [];
  data.items.forEach((item) => {
    if (!values.includes(item[colName])) {
      values.push(item[colName]);
    }
  });
  return values;
};

const setFilter = (filter: Filter) => {
  const existedFilterIndex = data.filters.findIndex((el) => el.colName === filter.colName);
  if (existedFilterIndex === -1) {
    data.filters.push(filter);
    return;
  }
  if (!filter.value) {
    data.filters.splice(existedFilterIndex, 1);
    return;
  }
  const existedFilter = data.filters[existedFilterIndex];
  if (existedFilter && existedFilter.value) {
    if (filter.type === 'multiple') {
      existedFilter.value = Array.from(new Set(existedFilter.value.concat(filter.value)));
    } else {
      existedFilter.value = filter.value;
    }
  }
};

const handleSelect = (val: number[]) => {
  if (props.structure.selection !== 'single'
    || !data.selectedItems.length) {
    return;
  }
  data.selectedItems = [];
  data.selectedItems.push(val[val.length - 1]);
};

const handleSelectAll = (val: boolean) => {
  if (!val) {
    data.selectedItems = [];
    return;
  }
  data.selectedItems = data.items.map((item) => item[data.field_id]);
};

const paginatedItems = computed(() => {
  const { pagination } = props.structure;
  if (!pagination) {
    return filteredItems.value;
  }
  return filteredItems.value
    .slice((data.currentPage - 1) * data.itemsPerPage, data.currentPage * data.itemsPerPage);
});

const maxPages = computed(() => Math.ceil(filteredItems.value.length / data.itemsPerPage));

const paginate = (pageNumber: number) => {
  data.currentPage = pageNumber;
};

const expandRow = (id: number) => {
  if (data.expandedRow === id) {
    data.expandedRow = null;
    return;
  }
  data.expandedRow = id;
};

</script>

<style scoped lang="scss">
  .colname_sortable {
    color: $accent;
    cursor: pointer;
  }
  .sticky-head {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 100;
  }
  th {
    text-align: left;
  }
  .active {
    color: blue;
  }

  .table{
    width: 100%;
    &__header {
      padding: 0 10px;
    }
    &__footer {
      display: flex;
      align-items: center;
      padding: 10px 0px;
      margin: 0px 14px;
      border-top: 1px solid #eee;
    }
    &__filter-icon {
      cursor: pointer;
    }
    &__wrapper {
      overflow: auto;
      display: grid;
    }
  }

  .pagination-description {
    margin-right: 10px;
  }

</style>
