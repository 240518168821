import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "column-filter" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

import { Filter } from '@/components/TableComponent/types';
import {
  onBeforeMount, PropType, Ref, ref,
} from 'vue';

type FilterValue = string[] | null;


export default /*@__PURE__*/_defineComponent({
  __name: 'ColumnFilter',
  props: {
  initial: Object as PropType<Filter | undefined>,
  colName: String as PropType<string>,
  items: Array as PropType<string[]>,
  label: String as PropType<string>,
  multiple: Boolean as PropType<boolean>,
},
  emits: [
  'setFilter',
],
  setup(__props, { emit: __emit }) {

const props = __props;

const selectedItem: Ref<FilterValue> = ref(null);

onBeforeMount(() => {
  if (props.initial) {
    selectedItem.value = props.initial.value;
  }
});

const emit = __emit;

const setFilter = () => {
  if (!props.colName) return;
  let value: string[] | null;
  if (typeof selectedItem.value === 'string') {
    value = [selectedItem.value];
  } else {
    value = selectedItem.value;
  }
  const filter: Filter = { colName: props.colName, value, type: props.multiple ? 'multiple' : 'single' };
  emit('setFilter', filter);
};


return (_ctx: any,_cache: any) => {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(" Фильтр по полю " + _toDisplayString(__props.label) + " ", 1),
    (__props.items && __props.items.length)
      ? (_openBlock(), _createBlock(_component_q_select, {
          key: 0,
          outlined: "",
          clearable: "",
          multiple: __props.multiple,
          modelValue: selectedItem.value,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((selectedItem).value = $event)),
            setFilter
          ],
          options: __props.items,
          label: __props.label
        }, null, 8, ["multiple", "modelValue", "options", "label"]))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, " Нет фильтров ")),
    (__props.multiple)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Можно выбрать несколько значений"))
      : _createCommentVNode("", true)
  ]))
}
}

})