import './styles/quasar.scss';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import LoadingBar from 'quasar/src/plugins/loading-bar/LoadingBar.js';import Screen from 'quasar/src/plugins/screen/Screen.js';;
import langRu from 'quasar/lang/ru';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  lang: langRu,
  plugins: {
    LoadingBar,
    Screen,
  },
};
