<template>
  <q-card class="modal__card">
    <q-card-section class="modal__header">
      <div class="q-ml-sm">
        <BugReport />
      </div>
      <div class="modal__title">{{ exposedContent.content?.header }}</div>
      <q-btn class="modal__close-btn" round flat icon="close" @click="emit('close')" />
    </q-card-section>

    <q-separator v-show="exposedContent.content?.header" />

    <q-card-section class="col scroll-y" style="grid-row: span 4">
      <Suspense>
        <component
          :is="modalComponentName"
          :params="modalComponentParams"
          ref="content"
          @close="emit('close')"
        />
        <template #fallback>
          <q-inner-loading :showing="true" label="Идет загрузка...">
            <AppLoader />
          </q-inner-loading>
        </template>
      </Suspense>
    </q-card-section>

    <q-separator />

    <q-card-actions class="modal-actions">
      <section id="modal-extra-actions" class="q-mb-sm full-width"></section>
      <section class="row full-width">
        <template v-if="exposedContent.content?.confirmButtons">
          <AppButton
            v-for="(button, index) in exposedContent.content?.confirmButtons"
            :key="index"
            v-bind="button"
            :icon="button.icon ? button.icon : 'save'"
            class="q-mr-xs"
          />
        </template>
        <q-space />
        <q-btn flat no-caps label="Отмена" color="accent" icon="close" @click="emit('close')" />
      </section>
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { useMainStore } from '@/store';
import { storeToRefs } from 'pinia';
import {
  computed, defineAsyncComponent, ref, reactive, watch,
} from 'vue';
import AppLoader from '@/components/AppLoader.vue';
import { Button } from '@/components/TableComponent/types';
import AppButton from '@/components/AppButton.vue';

type ModalData = {
  header: string;
  confirmButtons: Button[];
  maximized?: boolean;
};

export default { name: 'ModalContainer', components: { AppButton } };
</script>

<script setup lang="ts">
const { modalComponent } = storeToRefs(useMainStore());

const modalComponentName = computed(() => {
  try {
    const component = defineAsyncComponent(
      () => import(`~components/modals/${modalComponent.value.name}.vue`),
    );
    return component;
  } catch (error: any) {
    throw new Error(error);
  }
});

const modalComponentParams = computed(() => modalComponent.value.params || null);

const content = ref(null as unknown as ModalData | null);
const exposedContent = reactive({ content });
const emit = defineEmits(['close']);
</script>

<style>
.q-card.modal__card {
  padding: 0.5rem;
}
.on-left {
  margin-right: 2px !important;
}
.q-field--standout.q-field--highlighted .q-field__control {
  background: rgba(0, 0, 0, 0.05) !important;
}
.q-card__actions .q-btn {
  padding: 6px !important;
}
</style>

<style lang="scss" scoped>
.modal {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    max-width: 75%;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 0.25rem;
  }
  &__container {
    background: #fff;
    min-width: 200px;
    min-height: 100px;
    padding: 0 10px 10px;
  }
  &__card {
    display: grid;
    grid-template-rows: auto auto 1fr auto;
    max-width: fit-content;
  }
  &__close-btn {
    float: right;
    position: relative;
  }
  &-actions {
    padding: 0;
  }
}
</style>
