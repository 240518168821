<template>
  <q-header elevated v-if="store.isAuth">
    <q-toolbar class="bg-grey-3" >
      <router-link
        class="header__link text-accent"
        v-for="item in menu"
        :key="item.id"
        :to="item.link"
      >
        <q-btn flat :label="item.text" />
        <span class="header__link_note">
          {{item.note ? [item.note] : ''}}
        </span>
      </router-link>
      <div class="header__username text-primary">
        <q-avatar
          size="32px"
          color="light-green-6"
          text-color="white"
          class="q-mr-xs"
        >
          {{ avatarInitials }}
        </q-avatar>
        <div>{{ visibleUserName }}</div>
      </div>
      <q-space />
      <!-- <q-btn
        size="sm"
        push
        icon="save"
        color="white"
        text-color="accent"
        label=""
        class="q-ml-md"
        @click="store.changePassword()"/> -->
      <q-btn
        size="sm"
        push
        icon="logout"
        color="white"
        text-color="accent"
        label=""
        class="q-ml-md"
        @click="logout"/>
    </q-toolbar>
  </q-header>
</template>

<script setup lang="ts">
import {
  reactive,
  watch,
  computed,
  ref,
  nextTick,
} from 'vue';
import { getNameByMode } from '~utils/stringHelpers';
import { useAuthStore } from '@/store/auth/authModule';
import { useMainStore } from '@/store';
import {
  storeToRefs,
  getActivePinia,
  Pinia,
  Store,
} from 'pinia';

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>;
}
const $ResetPinia = (): void => {
  const pinia = getActivePinia() as ExtendedPinia;

  if (!pinia) {
    throw new Error('There is no stores');
  }

  pinia._s.forEach((store) => {
    store.$reset();
  });
};
const data = reactive({
  seamless: false,
  src: '',
  text: '',
});
const reportDialog = ref();
const store = useAuthStore();
const { userName } = storeToRefs(store);

const visibleUserName = computed(() => {
  if (userName) {
    return getNameByMode(userName.value, 'lastname');
  }
  return '';
});
const avatarInitials = computed(() => {
  if (userName) {
    return getNameByMode(userName.value, 'initials');
  }
  return '';
});

const logout = () => {
  $ResetPinia();
  useAuthStore().logout();
};

type MenuLink = {
  id: number,
  link: string,
  text: string,
  note?: string,
};

const menu: MenuLink[] = [
  // {
  //   id: 1,
  //   link: '/dashboard',
  //   text: 'Главная',
  // },
  // {
  //   id: 2,
  //   link: '/struct',
  //   text: 'API',
  // },
];

</script>

<style scoped lang="scss">

  .header {
    &__link {
      text-decoration: none;
    }
    &__username {
      display: flex;
      align-items: center;
      font-size: 1rem;
    }
  }
</style>

<style>
.light-form textarea {
  color: #fff;
}
.light-form .q-field__label,
.light-form .q-field__messages {
  color:#fff;
}
</style>

function parseSvgString(svgContent: any): Node {
  throw new Error('Function not implemented.');
}

function parseSvgString(svgContent: any): Node {
  throw new Error('Function not implemented.');
}
