import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import {
  onMounted,
  PropType,
  computed,
} from 'vue';
import { Action, ActionParams } from './types';


export default /*@__PURE__*/_defineComponent({
  __name: 'JsonStringCell',
  props: {
  text: Array as PropType<any>,
  fields: Array as PropType<any>,
  action: Object as PropType<Action>,
  actionParams: Object as PropType<ActionParams>,
},
  emits: ['action'],
  setup(__props, { emit: __emit }) {

const props = __props;

const nameField = computed(() => {
  if (props.fields) {
    const jsonField = props.fields[0].name_column.split('.')[0];
    if (jsonField) {
      return jsonField.substring(0, jsonField.length - 1);
    }
  }
  return '';
});

// eslint-disable-next-line arrow-body-style
const structure = computed(() => {
  // eslint-disable-next-line arrow-body-style
  const innerFields = props.fields.map((i: any) => {
    // eslint-disable-next-line arrow-body-style
    // eslint-disable-next-line no-param-reassign
    const field = { ...i };
    [field.parent, field.name_column] = i.name_column.split('.');
    return field;
  });
  return {
    hide_head: true,
    direction: 'row',
    expandable_rows: false,
    pagination: false,
    title: '',
    field_id: nameField.value,
    columns: innerFields,
  };
});
const emit = __emit;

const clickHandler = () => {
  const { action } = props;
  if (!action) {
    return;
  }
  const params = {
    actionId: action.name,
    actionType: action.type,
    params: props.actionParams,
  };
  emit('action', params);
};

return (_ctx: any,_cache: any) => {
  const _component_TableComponent = _resolveComponent("TableComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    (__props.text?.length)
      ? (_openBlock(), _createBlock(_component_TableComponent, {
          key: 0,
          data: __props.text,
          structure: structure.value
        }, null, 8, ["data", "structure"]))
      : _createCommentVNode("", true)
  ]))
}
}

})