<template>
  <div class="login container">
    <q-img
      src="@/assets/ecosafety_mc_logo_2017.png"
      class="q-mx-auto q-mt-md"
      width="200px"
    />
    <LoginForm />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoginForm from '@/components/login/LoginForm.vue';

export default defineComponent({
  name: 'LoginView',
  components: {
    LoginForm,
  },
  setup() {
    return {};
  },
});
</script>
