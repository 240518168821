<template>
  <q-layout>
    <BaseLayout v-if="isAuth && !authInProcess" :isLoading="isLoading" />
    <!-- <AuthLayout v-else /> -->
    <router-view v-else>
    </router-view>

    <q-inner-loading v-if="isLoading" :showing="isLoading">
      <AppLoader />
    </q-inner-loading>
    <q-footer
      class="bg-grey-3 text-primary"
      :class="{
        'small-text': $q.screen.lt.sm,
      }"
    >
      <q-img
        v-if='!isAuth'
        src="@/assets/footer.svg"
        class='full-width'
      />
      <q-toolbar class="base-footer column">
        <div>
          <div class='text-center'>
            <q-btn
              no-caps
              flat
              dense
              class='q-py-none'
              icon='call'
              label="+7 (812) 325-03-05"
              href="tel:+78123250305"
              :class="{
                'small-text': $q.screen.lt.sm,
              }"
            />
            <q-btn
              no-caps
              flat
              dense
              class='q-py-none'
              icon='email'
              label="info@ecosafety.ru"
              href="mailto:info@ecosafety.ru"
              :class="{
                'small-text': $q.screen.lt.sm,
              }"
            />
          </div>
          <div style='padding:0 5px;' class='text-center'>
            191119, Санкт-Петербург, ул. Достоевского, 40-44
          </div>
        </div>
        <div class='row vertical-middle'>
          <q-space />
          <div class="copyright">
            Группа компаний Эко-безопасность © 2011-{{ new Date().getFullYear() }}
          </div>
        </div>
      </q-toolbar>
    </q-footer>

    <q-dialog
      v-model='needChange'
      persistent
      transition-show="scale"
      transition-hide="scale"
      class='first-acquaintance'
    >
      <q-card class="bg-warning text-blue-grey-10" style="width: 300px">
        <q-card-section class='q-pa-sm'>
          <div class="text-h6 text-center">Измените пароль!</div>
        </q-card-section>
        <q-form @submit.prevent="tryChangePass">
          <q-card-section class="q-pt-none">
              <q-input
                dense
                filled
                :type="isPwd ? 'password' : 'text'"
                v-model="data.newPpassword"
                label="Новый пароль"
                lazy-rules
                class='q-mt-xs'
                bg-color="white"
                :rules="[
                  val => val !== null && val !== '' || 'Обязательное поле',
                ]"
              >
              <template v-slot:append>
                  <q-icon
                    :name="isPwd ? 'visibility_off' : 'visibility'"
                    class="cursor-pointer"
                    @click="isPwd = !isPwd"
                  />
                </template>
              </q-input>

              <q-input
                dense
                filled
                :type="isPwdRepeat ? 'password' : 'text'"
                v-model="data.newPpasswordRepeat"
                label="Еще раз"
                lazy-rules
                class='q-mt-xs'
                bg-color="white"
                :rules="[
                  val => val !== null && val !== '' || 'Обязательное поле',
                ]"
              >
              <template v-slot:append>
                  <q-icon
                    :name="isPwdRepeat ? 'visibility_off' : 'visibility'"
                    class="cursor-pointer"
                    @click="isPwdRepeat = !isPwdRepeat"
                  />
                </template>
              </q-input>
          </q-card-section>

          <q-card-actions align="right" class="bg-white text-blue-grey-10">
            <q-btn flat icon='logout' label="Выйти" @click.prevent='logout' />
            <q-btn glossy push icon='save' label="Сохранить" type="submit"/>
          </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>

    <q-dialog
      v-model="modal"
      :maximized="maximized"
      :full-height="maximized"
      :full-width="maximized"
      :class="{
        'prepare-report': reportMode,
      }"
    >
      <Suspense>
        <ModalContainer @close="closeModal"> </ModalContainer>
      </Suspense>
    </q-dialog>
    <q-dialog v-model="messageShown" seamless position="bottom">
      <q-card class="row message-card bg-amber-6 text-black">
        <q-card-section class="row items-center no-wrap">
          <pre class='message-wrapper row text-center'>{{ messageContent }}</pre>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-layout>
</template>

<script setup lang="ts">
import {
  computed,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';

import ModalContainer from '~components/modals/ModalContainer.vue';
import { useMainStore } from '@/store';
import { useAuthStore } from '@/store/auth/authModule';
import {
  storeToRefs,
  getActivePinia,
  Pinia,
  Store,
} from 'pinia';
import AppLoader from '~components/AppLoader.vue';
import BaseLayout from '@/layouts/BaseLayout.vue';
// import AuthLayout from '@/layouts/AuthLayout.vue';
import { height, tabsHeaderHeight } from './constants';

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>;
}

const $ResetPinia = (): void => {
  const pinia = getActivePinia() as ExtendedPinia;

  if (!pinia) {
    throw new Error('There is no stores');
  }

  pinia._s.forEach((store) => {
    store.$reset();
  });
};

const authStore = useAuthStore();

const isLoading = ref(false);

const { isAuth, needChange } = storeToRefs(authStore);

const authInProcess = ref(false);
const isPwd = ref(true);
const isPwdRepeat = ref(true);
const data = reactive({
  newPpassword: '',
  newPpasswordRepeat: '',
});

const {
  modal,
  maximized,
  message,
  messageContent,
  reportMode,
} = storeToRefs(useMainStore());

// onBeforeMount(async () => {
//   isLoading.value = true;
//   isLoading.value = false;
// });

const messageShown = computed(() => Boolean(message.value));

const closeModal = () => {
  useMainStore().closeModal();
};

watch(
  () => authStore.processing,
  () => {
    if (authStore.processing) {
      isLoading.value = true;
    } else isLoading.value = false;
  },
);

const tryChangePass = async () => {
  if (data.newPpassword === data.newPpasswordRepeat) {
    let hash = '';
    if (authStore.hash) {
      hash = authStore.hash;
    }
    const result = await authStore.changePassword(data.newPpassword, hash);
    if (result) {
      needChange.value = false;
      authStore.hash = '';
      authStore.autenticate();
      useMainStore().showMessage(authStore.user.err_text);
    }
  } else {
    useMainStore().showMessage('Пароли не совпадают');
  }
};

const logout = () => {
  $ResetPinia();
  useAuthStore().logout();
};

const wrapperStyleHeight = `calc(100vh - ${height}px)`;
const tabsWrapperStyleHeight = `calc(100vh - ${height + tabsHeaderHeight}px)`;
</script>

<style lang="scss">
noscript {
  display: none;
}
.prepare-report.fullscreen,
.prepare-report .fixed,
.prepare-report .fixed-full {
  position: absolute !important;
}
// .copyright {
//   padding: 5px;
// }
.small-text * {
  font-size: 0.6rem;
}
.q-layout {
  // height: 100vh;
  // overflow: scroll;
  // position: fixed !important;
  // overflow: scroll !important;
}
.q-footer {
  position: fixed !important;
}
.auth-banner {
  flex-direction: column;
}
.first-acquaintance .q-dialog__inner .q-card__section {
  padding: 0.75rem !important;
}
.wrapper {
  display: flex;
  flex-direction: column;
  height: v-bind(wrapperStyleHeight);
}
.q-tab-panel > .wrapper {
  height: v-bind(tabsWrapperStyleHeight);
}
.container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.button-group {
  justify-content: space-between;
}
.fab {
  position: absolute !important;
  z-index: 10000 !important;
  &_centered {
    top: calc(50% - 28px);
    left: 50%;
    transform: translateX(-28px);
  }
  &_bottom-right {
    bottom: 28px;
    right: 28px;
  }
}
.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.q-dialog__inner--minimized {
  padding: 5px !important;
}
.q-field__bottom {
  padding: 1px 12px 0px !important;
}
.base-footer {
  min-height: 30px !important;
}
.message-card .q-card__section {
  margin: 0 auto;
  height: fit-content;
}
.message-wrapper {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
}
</style>
