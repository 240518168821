// import { TableStructure } from '~components/TableComponent/types';
import { defineStore } from 'pinia';
import Api, { Payload } from '~utils/Api';
import { useMainStore } from '~store/index';
import { modules } from '@/constants';
import {
  Chapter,
  Answer,
  Slot,
  Appointment,
} from './types';
// const api = new Api();

export const useEcoHospitalStore = defineStore('ecohospital', {
  state: () => ({
    poll: [] as Chapter[],
    updating: false,
  }),
  getters: {},
  actions: {
    async getPoll(): Promise<void> {
      const params: Payload = {
        Name_event: 'GET:poll_questions',
        Name_module: modules.eco,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        this.poll = JSON.parse(result.data?.[0]?.chapters ?? []);
      }
    },
    async getAppointmenCalendar(): Promise<Slot[]> {
      this.updating = true;
      try {
        const params: Payload = {
          Name_event: 'GET:calendar',
          Name_module: modules.eco,
          ...useMainStore().getConstantParams,
        };
        const result = await Api.get(params);
        if (!result.errorType) {
          return JSON.parse(result.data?.[0].result);
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.updating = false;
      }
      return [];
    },
    async getDocuments(): Promise<any> {
      const params: Payload = {
        Name_event: 'GET:list_load',
        Name_module: modules.eco,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        return result.data;
      }
      return [];
    },
    async getFilePath(id_document: number): Promise<any> {
      const params: Payload = {
        Name_event: 'GET:file_path',
        Name_module: modules.eco,
        id_document,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        return result.data;
      }
      return false;
    },
    async sendChaptersAnswers(context: Answer): Promise<any> {
      const params = {
        Name_event: 'POST:poll_answers',
        Name_module: modules.eco,
        id_page: context.page,
        id_poll: 1,
        json_params: JSON.stringify(context.chapter),
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params, true);
      if (!result.errorType) {
        await this.getPoll();
        return true;
      } return null;
    },
    async createAppointment(context: Appointment): Promise<any> {
      const params = {
        Name_event: 'POST:setup_appointment',
        Name_module: modules.eco,
        json_params: JSON.stringify({
          date: context.date,
          time: context.time,
          tel: context.tel,
        }),
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params, true);
      if (!result.errorType) {
        this.getAppointmenCalendar();
        return result.data;
      } return null;
    },
  },
});
