import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/ecosafety_mc_logo_2017.png'


const _hoisted_1 = { class: "login container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_LoginForm = _resolveComponent("LoginForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_img, {
      src: _imports_0,
      class: "q-mx-auto q-mt-md",
      width: "200px"
    }),
    _createVNode(_component_LoginForm)
  ]))
}