<template>
  <q-btn
    v-bind="props"
    push
    no-caps
    color="white"
    text-color="accent"
    :type="type"
    :disable="disable"
    :loading="loading"
    @click="handler"
  >
    <q-menu v-if="isMenuAnchor">
      <Suspense>
        <component
          :is="menuComponent"
          :params="props.context || props.action.customParam"
          @update="updateHandler"
        />
        <template #fallback>
          <q-card>
            <div class="q-py-md q-px-xl">
              <AppLoader />
            </div>
          </q-card>
        </template>
      </Suspense>
    </q-menu>
    <input type="file" v-if="isUploader" @change="uploadHandler" aria-label="-/-" />
    <template v-slot:loading>
      <q-spinner-hourglass />
    </template>
  </q-btn>
</template>

<script lang="ts">
import {
  computed, defineAsyncComponent, PropType, reactive,
} from 'vue';
import { actionHandler } from '@/actions/actionHandler';
import { Action } from './TableComponent/types';
import AppLoader from './AppLoader.vue';

export default { name: 'AppButton' };
</script>

<script setup lang="ts">
const props = defineProps({
  label: {
    type: String as PropType<string>,
  },
  type: {
    type: String as PropType<string>,
  },
  icon: {
    type: String as PropType<string>,
  },
  disable: {
    type: Boolean as PropType<boolean>,
    default: () => false,
  },
  loading: {
    type: Boolean as PropType<boolean>,
    default: () => false,
  },
  action: {
    type: Object as PropType<Action>,
    required: true,
  },
  context: {
    type: Object as PropType<Record<string, unknown>>,
  },
});

const emit = defineEmits(['success', 'upload']);

const isMenuAnchor = computed(() => props.action.type === 'menu');

const isUploader = computed(() => props.type === 'upload');

// const menuComponent = defineAsyncComponent(
//   () => import(`~components/menus/${props.action.name}.vue`),
// );

const uploadHandler = (event: Event) => {
  const target = event.currentTarget as HTMLInputElement;
  const [file]: File[] = [...(target.files as FileList)];
  const type = props.action.customParam;
  const action = {
    ...props.action,
    customParam: {
      type,
      file,
    },
  };
  actionHandler(action);
};

const updateHandler = (val: any) => {
  console.log('update', val);
};

const onAction = async () => {
  const result = await actionHandler(props.action);
  if (result) {
    emit('success');
  }
};

const handler = computed(() => {
  if (isUploader.value) {
    return updateHandler;
  }
  return onAction;
});
</script>

<style scoped>
input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
</style>
