import script from "./BaseLayout.vue?vue&type=script&setup=true&lang=ts"
export * from "./BaseLayout.vue?vue&type=script&setup=true&lang=ts"

import "./BaseLayout.vue?vue&type=style&index=0&id=612fc6e4&lang=css"

const __exports__ = script;

export default __exports__
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPageContainer,QInnerLoading});
