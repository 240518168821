<template>
  <q-btn flat color="accent" @click.stop="clickHandler">
    <q-icon v-if="value?.icon" :name="value?.icon"></q-icon>
    {{value?.text}}
  </q-btn>
</template>

<script setup lang="ts">
import { PropType, ref } from 'vue';
import { ActionParams, FieldContent } from '@/components/TableComponent/types';

const props = defineProps({
  value: {
    type: Object as PropType<FieldContent>,
    default: () => {},
  },
  actionParams: {
    type: Object as PropType<ActionParams>,
    default: () => {},
  },
});

const action = ref(props.value.action);

const emit = defineEmits(['action']);

const clickHandler = () => {
  const params = {
    actionId: action.value.name,
    actionType: action.value.type,
    params: props.actionParams,
  };
  emit('action', params);
};

</script>

<style scoped>

</style>
