import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Suspense as _Suspense, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "q-ml-sm" }
const _hoisted_2 = { class: "modal__title" }
const _hoisted_3 = { class: "row full-width" }


import { useMainStore } from '@/store';
import { storeToRefs } from 'pinia';
import {
  computed, defineAsyncComponent, ref, reactive, watch,
} from 'vue';
import AppLoader from '@/components/AppLoader.vue';
import { Button } from '@/components/TableComponent/types';
import AppButton from '@/components/AppButton.vue';

type ModalData = {
  header: string;
  confirmButtons: Button[];
  maximized?: boolean;
};

const __default__ = { name: 'ModalContainer', components: { AppButton } };

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const { modalComponent } = storeToRefs(useMainStore());

const modalComponentName = computed(() => {
  try {
    const component = defineAsyncComponent(
      () => import(`~components/modals/${modalComponent.value.name}.vue`),
    );
    return component;
  } catch (error: any) {
    throw new Error(error);
  }
});

const modalComponentParams = computed(() => modalComponent.value.params || null);

const content = ref(null as unknown as ModalData | null);
const exposedContent = reactive({ content });
const emit = __emit;

return (_ctx: any,_cache: any) => {
  const _component_BugReport = _resolveComponent("BugReport")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, { class: "modal__card" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, { class: "modal__header" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_BugReport)
          ]),
          _createElementVNode("div", _hoisted_2, _toDisplayString(exposedContent.content?.header), 1),
          _createVNode(_component_q_btn, {
            class: "modal__close-btn",
            round: "",
            flat: "",
            icon: "close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
          })
        ]),
        _: 1
      }),
      _withDirectives(_createVNode(_component_q_separator, null, null, 512), [
        [_vShow, exposedContent.content?.header]
      ]),
      _createVNode(_component_q_card_section, {
        class: "col scroll-y",
        style: {"grid-row":"span 4"}
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_Suspense, null, {
            fallback: _withCtx(() => [
              _createVNode(_component_q_inner_loading, {
                showing: true,
                label: "Идет загрузка..."
              }, {
                default: _withCtx(() => [
                  _createVNode(AppLoader)
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(modalComponentName.value), {
                params: modalComponentParams.value,
                ref_key: "content",
                ref: content,
                onClose: _cache[1] || (_cache[1] = ($event: any) => (emit('close')))
              }, null, 40, ["params"]))
            ]),
            _: 1
          }))
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      _createVNode(_component_q_card_actions, { class: "modal-actions" }, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createElementVNode("section", {
            id: "modal-extra-actions",
            class: "q-mb-sm full-width"
          }, null, -1)),
          _createElementVNode("section", _hoisted_3, [
            (exposedContent.content?.confirmButtons)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(exposedContent.content?.confirmButtons, (button, index) => {
                  return (_openBlock(), _createBlock(AppButton, _mergeProps({
                    key: index,
                    ref_for: true
                  }, button, {
                    icon: button.icon ? button.icon : 'save',
                    class: "q-mr-xs"
                  }), null, 16, ["icon"]))
                }), 128))
              : _createCommentVNode("", true),
            _createVNode(_component_q_space),
            _createVNode(_component_q_btn, {
              flat: "",
              "no-caps": "",
              label: "Отмена",
              color: "accent",
              icon: "close",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('close')))
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})