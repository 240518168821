import { TableRow, TableStructure } from '~components/TableComponent/types';
// eslint-disable-next-line import/no-cycle
import { useAuthStore } from '@/store/auth/authModule';
// eslint-disable-next-line import/no-cycle
import { useMainStore } from '@/store';
import { delayedQueryDelay, DADATA_REQUEST_COUNT } from '@/constants';
import { PassChanged } from '@/store/auth/types';
import { Suggestion } from '@/types';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import md5 from 'blueimp-md5';

type DelayedQuery = {
  id_query: number;
}

type DelayedQueryResponse = {
  done: 0 | 1;
  result: string | null;
  structure?: TableStructure;
}

type DaDataResponse = {
  suggestions: Suggestion[]
}

export type LoginResult = {
  [index: number]: any[];
}

export type HashLoginResult = {
  id_login: number,
  id_group_login: number,
  vrach_type: number,
  fio: string,
  org_name: string,
  id_people: number,
  lk_login: number,
  TK: string,
  err: number,
  res: number,
  err_text: string,
}

export type Method = 'GET' | 'POST';

export type Payload = {
  [key: string]: string | number | boolean | null
};

export type Result<T = TableRow> = {
  errorText: string,
  errorType: number,
  body: T[],
  body2: [{ structure: string }] | null,
}

export type ToReturn<T = TableRow> = {
  errorText: string,
  errorType: number,
  data?: T[],
  structure?: TableStructure,
}

export type NoDataResponse = {
  err: number,
  err_text: string,
}

export const API_ENDPOINT = 'test';
export const LOGIN_ENDPOINT = 'client_login';
export const UPDATE_PSW_ENDPOINT = 'changePassword';
export const HASH_LOGIN_ENDPOINT = 'hash_login';
export const BASE_URL = 'https://host1.medsafe.tech:40443/';
export const POSTFIX = 'api/';

const API_URL = `${BASE_URL}${POSTFIX}${API_ENDPOINT}`;
const LOGIN_URL = `${BASE_URL}${POSTFIX}${LOGIN_ENDPOINT}`;
const HASH_LOGIN_URL = `${BASE_URL}${POSTFIX}${HASH_LOGIN_ENDPOINT}`;
const CHANGE_PASS_URL = `${BASE_URL}${POSTFIX}${UPDATE_PSW_ENDPOINT}`;

const defaultHeaders = {
  'Content-Type': 'application/json',
};
const loginHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

class Api {
  showMessage(text: string) {
    if (text === 'Открыть интерфейс создания пароля' || text === 'Вход подтверждён') return;
    if (text) useMainStore().showMessage(text);
  }

  updateLog(url: string) {
    if (url) useMainStore().requestsLine(url);
  }

  async getImei() {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();
    return `${md5(result.visitorId)}_EcoHospital`;
  }

  async get(
    payload: Payload,
    signal: AbortSignal | null | undefined = undefined,
  ): Promise<ToReturn> {
    const queryParams = payload;
    if (payload.delayed) {
      queryParams.Name_event = `${payload.Name_event}_delayed`;
    }
    const result: Result = await this.request(`${API_URL}?json=${JSON.stringify(queryParams)}`, 'GET', signal);
    console.log(payload, result);
    let toReturn: ToReturn;
    if (payload.delayed && result.body[0]) {
      toReturn = await this.getDelayedQuery({
        ...payload,
        ...result.body[0] as DelayedQuery,
      });
      return toReturn;
    }
    toReturn = {
      errorText: result?.errorText ?? '',
      errorType: result?.errorType,
      data: result?.body,
    };
    if (result?.body2) {
      try {
        const structure = JSON.parse(result.body2[0].structure);
        structure.event = payload.event;
        toReturn.structure = structure;
      } catch (error) {
        console.log(error);
      }
      toReturn.structure = JSON.parse(result.body2[0].structure);
    }
    return toReturn;
  }

  async post(payload: Payload, type: boolean | null = null): Promise<ToReturn> {
    const method = 'POST';
    let result: Result;
    if (!type) {
      result = await this.request(`${API_URL}?json=${JSON.stringify(payload)}`, method);
    } else {
      const body = `json=${JSON.stringify(payload)}`;
      result = await this.request(`${API_URL}`, method, null, body, loginHeaders);
    }
    console.log(payload, result);
    const toReturn: ToReturn = {
      errorText: result.errorText,
      errorType: result.errorType,
      data: result.body,
    };
    if (result.body2) {
      try {
        toReturn.structure = JSON.parse(result.body2[0].structure);
      } catch (error) {
        console.log(error);
      }
    }
    return toReturn;
  }

  async login(
    payload: Payload,
  ): Promise<LoginResult> {
    const method = 'POST';
    const body = `json=${JSON.stringify(payload)}`;
    const toReturn: LoginResult = await this.request(`${LOGIN_URL}`, method, null, body, loginHeaders);
    console.log(payload, toReturn, toReturn[0]);
    return toReturn[0];
  }

  async hashLogin(
    payload: Payload,
  ): Promise<HashLoginResult> {
    const method = 'POST';
    const body = `json=${JSON.stringify(payload)}`;
    const toReturn: HashLoginResult[] = await this.request(`${HASH_LOGIN_URL}`, method, null, body, loginHeaders);
    console.log(payload, toReturn[0]);
    return toReturn[0];
  }

  async changePassword(
    payload: Payload,
  ): Promise<PassChanged> {
    const method = 'POST';
    const body = `json=${JSON.stringify(payload)}`;
    const toReturn: PassChanged[] = await this.request(`${CHANGE_PASS_URL}`, method, null, body, loginHeaders);
    console.log(payload, toReturn, toReturn[0]);
    return toReturn[0];
  }

  async all(method: Method, payload: Payload): Promise<ToReturn> {
    return method === 'GET' ? this.get(payload) : this.post(payload);
  }

  async request(
    url: string,
    method: Method,
    signal: AbortSignal | null = null,
    body: FormData | null | string = null,
    headers: HeadersInit | null = null,
  ): Promise<any> {
    this.updateLog(url);
    try {
      const response = await fetch(url, {
        method,
        headers: headers || defaultHeaders,
        body,
        signal,
      });
      let result;
      if (body instanceof FormData) {
        result = await response.text();
        if (result?.includes('sucess')) useMainStore().showMessage('Отчет отправлен! \n Спасибо за обратную связь!');
        else throw new Error('Отправка сорвалась... \n Пожалуйста, повторите снова');
      } else result = await response.json();
      if (result.errorType === 1 && result.errorText === 'Incorrect token') {
        useAuthStore().logout();
        return false;
      }
      if (result.errorType && result.errorType !== 1) {
        this.showMessage(result.errorText);
        return false;
      }
      if (
        result?.body?.length
        && (result.body[0]?.err_text || result.body[0].err_txt || result.body[0].error)
      ) {
        const text = result.body[0].err_txt || result.body[0].err_text || result.body[0].error;
        this.showMessage(text);
      }
      if (result?.body?.length && result.body[0]?.err) {
        return false;
      }
      return result;
    } catch (error: any) {
      console.log(error);
      if (!error.message.includes('signal') && !error.message.includes('aborted')) {
        useMainStore().showMessage('Что-то пошло не так...\nПопробуйте повторить запрос\n\nЕсли все "идет не так" слишком долго,\nбудьте уверены, мы решаем проблему');
      }
      return null;
    }
  }

  async sendLargeJson(payload: Payload) {
    try {
      const response = await this.post({ json: JSON.stringify(payload) }, true);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async getFile(hash: string): Promise<BlobPart | boolean> {
    try {
      const response = await fetch(`${BASE_URL}${hash}`);
      if (response.ok) {
        const result = await response.blob();
        return result;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async uploadFile(formData: FormData, url: string) {
    try {
      const upload = await this.request(`https://host2.medsafe.tech/api/${url}/`, 'POST', null, formData, {});
      return upload;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async getDelayedQuery(delayedQuery: DelayedQuery): Promise<ToReturn> {
    const response = await this.tryToGetDelayedQueryData(delayedQuery);
    if (response.done === 1) {
      try {
        const { result, structure } = response;
        if (result) {
          return {
            errorText: '',
            errorType: 0,
            data: JSON.parse(result),
            structure,
          };
        }
        return {
          errorText: '',
          errorType: 0,
        };
      } catch (error) {
        console.log(error);
      }
      return {
        errorText: '',
        errorType: 0,
        ...response as DelayedQueryResponse,
      };
    }
    await this.delay(delayedQueryDelay);
    return this.getDelayedQuery(delayedQuery);
  }

  async tryToGetDelayedQueryData(delayedQuery: DelayedQuery): Promise<DelayedQueryResponse> {
    try {
      const response = await this.get({
        ...delayedQuery,
        Name_event: 'GET:delayed_result',
        delayed: false,
      });
      if (response) {
        const { data } = response;
        if (data) {
          const res = data[0] as DelayedQueryResponse;
          return {
            ...res,
            structure: response.structure,
          };
        }
      }
    } catch (error) {
      console.log(error);
    }
    return {
      done: 0,
      result: null,
    };
  }

  async delay(ms: number) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // async getOrganizationByTin(query: string): Promise<DaDataResponse> {
  //   const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party';
  //   const token = 'd925c94a83608dec71618801f5280ba9f90bc205';
  //   try {
  //     const response = await fetch(url, {
  //       method: 'POST',
  //       mode: 'cors',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         // eslint-disable-next-line quote-props
  //         'Accept': 'application/json',
  //         // eslint-disable-next-line quote-props
  //         'Authorization': `Token ${token}`,
  //       },
  //       body: JSON.stringify({ query, count: DADATA_REQUEST_COUNT }),
  //     });
  //     const result = await response.json();
  //     return result;
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   return { suggestions: [] };
  // }
}

export default new Api();
