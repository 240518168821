import Cookies from 'quasar/src/plugins/cookies/Cookies.js';;

export type CookieOptions = {
    expires?: string | number | Date | undefined;
    path?: string | undefined;
    domain?: string | undefined;
    sameSite?: 'Lax' | 'Strict' | 'None' | undefined;
    httpOnly?: boolean | undefined;
    secure?: boolean | undefined;
    other?: string | undefined;
} | undefined;

export function getCookie(name: string) {
  return Cookies.get(name);
}

export function setCookie(name: string, value: string, options: CookieOptions) {
  return Cookies.set(name, value, options);
}

export function deleteCookie(name: string) {
  return Cookies.remove(name);
}
