import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "editable" }

import {
  ref, PropType, computed,
} from 'vue';
import { FieldContent, ActionParams } from './types';


export default /*@__PURE__*/_defineComponent({
  __name: 'EditableCell',
  props: {
  text: {
    type: String as PropType<string>,
    default: () => '',
  },
  width: {
    type: Number as PropType<number>,
    default: () => 202,
  },
  value: {
    type: Object as PropType<FieldContent>,
    default: () => {},
  },
  actionParams: {
    type: Object as PropType<ActionParams>,
    default: () => {},
  },
},
  emits: ['action'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const textWidth = computed(() => `width: ${props.width}px`);
const fieldWidth = computed(() => `min-width: ${props.width}px`);
const editable = ref(props.text);
const isEdit = ref(false);

const edit = () => {
  isEdit.value = true;
};

const save = () => {
  const data = {
    actionId: props.value.action.name,
    actionType: 'event',
    params: { text: editable.value, ...props.actionParams },
  };
  emit('action', data);
  isEdit.value = false;
};


return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("span", {
      class: "editable__value",
      style: _normalizeStyle(textWidth.value)
    }, _toDisplayString(editable.value), 5), [
      [_vShow, !isEdit.value]
    ]),
    (isEdit.value)
      ? (_openBlock(), _createBlock(_component_q_input, {
          key: 0,
          name: "editable",
          outlined: "",
          autofocus: "",
          dense: "",
          style: _normalizeStyle(fieldWidth.value),
          class: "editable__input",
          modelValue: editable.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((editable).value = $event)),
          onKeyup: _withKeys(save, ["enter"]),
          onFocusout: save,
          ref: "input"
        }, null, 8, ["style", "modelValue"]))
      : _createCommentVNode("", true),
    (!isEdit.value)
      ? (_openBlock(), _createBlock(_component_q_btn, {
          key: 1,
          onClick: _withModifiers(edit, ["stop"]),
          class: "editable__button"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_icon, { name: "edit" })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (isEdit.value)
      ? (_openBlock(), _createBlock(_component_q_btn, {
          key: 2,
          onClick: _withModifiers(save, ["stop"]),
          class: "editable__button"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_icon, { name: "save" })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})