<template>
  <div :style="style" class="expandable-cell">
    <span class="expandable-cell__text shadow-1">
      {{text}}
    </span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ExpandableCell',
  props: {
    text: {
      type: String as PropType<string>,
      default: '',
    },
    width: {
      type: Number as PropType<number>,
      default: 100,
    },
  },
  setup(props) {
    const style = computed(() => `width: ${props.width}px`);

    return {
      style,
    };
  },
});
</script>

<style lang="scss" scoped>
.expandable-cell {
  position: relative;
  width: 100%;
  height: 100%;
  // overflow-x: hidden;
  &__text {
    width: 100%;
    padding: 3px;
    background: rgba(0, 0, 0, 0.01);
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: absolute;
    top: calc(100% - 33px);
    &:hover {
      background: #fff;
      overflow-x: visible;
      white-space: initial;
      z-index: 3000;
    }
  }
}
</style>
