import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {
  PropType,
} from 'vue';
import { Action, ActionParams } from './types';


export default /*@__PURE__*/_defineComponent({
  __name: 'SimpleCell',
  props: {
  text: String as PropType<string>,
  action: Object as PropType<Action>,
  actionParams: Object as PropType<ActionParams>,
},
  emits: ['action'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const clickHandler = () => {
  const { action } = props;
  if (!action) {
    return;
  }
  const params = {
    actionId: action.name,
    actionType: action.type,
    params: props.actionParams,
  };
  emit('action', params);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({clickable: __props.action}),
    onClick: clickHandler
  }, _toDisplayString(__props.text), 3))
}
}

})