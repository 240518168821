import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "spinner-wrapper" }


import { PropType } from 'vue';

const __default__ = { name: 'AppLoader' };

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
  size: {
    type: String as PropType<string>,
    default: '50px',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_q_spinner_ios = _resolveComponent("q-spinner-ios")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_spinner_ios, {
      color: "accent",
      size: __props.size
    }, null, 8, ["size"])
  ]))
}
}

})