import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, isRef as _isRef, Suspense as _Suspense } from "vue"
import _imports_0 from '@/assets/footer.svg'


const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "row vertical-middle" }
const _hoisted_3 = { class: "copyright" }
const _hoisted_4 = { class: "message-wrapper row text-center" }

import {
  computed,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';

import ModalContainer from '~components/modals/ModalContainer.vue';
import { useMainStore } from '@/store';
import { useAuthStore } from '@/store/auth/authModule';
import {
  storeToRefs,
  getActivePinia,
  Pinia,
  Store,
} from 'pinia';
import AppLoader from '~components/AppLoader.vue';
import BaseLayout from '@/layouts/BaseLayout.vue';
// import AuthLayout from '@/layouts/AuthLayout.vue';
import { height, tabsHeaderHeight } from './constants';

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

_useCssVars(_ctx => ({
  "ac6554d8": (wrapperStyleHeight),
  "66a81232": (tabsWrapperStyleHeight)
}))

const $ResetPinia = (): void => {
  const pinia = getActivePinia() as ExtendedPinia;

  if (!pinia) {
    throw new Error('There is no stores');
  }

  pinia._s.forEach((store) => {
    store.$reset();
  });
};

const authStore = useAuthStore();

const isLoading = ref(false);

const { isAuth, needChange } = storeToRefs(authStore);

const authInProcess = ref(false);
const isPwd = ref(true);
const isPwdRepeat = ref(true);
const data = reactive({
  newPpassword: '',
  newPpasswordRepeat: '',
});

const {
  modal,
  maximized,
  message,
  messageContent,
  reportMode,
} = storeToRefs(useMainStore());

// onBeforeMount(async () => {
//   isLoading.value = true;
//   isLoading.value = false;
// });

const messageShown = computed(() => Boolean(message.value));

const closeModal = () => {
  useMainStore().closeModal();
};

watch(
  () => authStore.processing,
  () => {
    if (authStore.processing) {
      isLoading.value = true;
    } else isLoading.value = false;
  },
);

const tryChangePass = async () => {
  if (data.newPpassword === data.newPpasswordRepeat) {
    let hash = '';
    if (authStore.hash) {
      hash = authStore.hash;
    }
    const result = await authStore.changePassword(data.newPpassword, hash);
    if (result) {
      needChange.value = false;
      authStore.hash = '';
      authStore.autenticate();
      useMainStore().showMessage(authStore.user.err_text);
    }
  } else {
    useMainStore().showMessage('Пароли не совпадают');
  }
};

const logout = () => {
  $ResetPinia();
  useAuthStore().logout();
};

const wrapperStyleHeight = `calc(100vh - ${height}px)`;
const tabsWrapperStyleHeight = `calc(100vh - ${height + tabsHeaderHeight}px)`;

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_footer = _resolveComponent("q-footer")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, null, {
    default: _withCtx(() => [
      (_unref(isAuth) && !authInProcess.value)
        ? (_openBlock(), _createBlock(BaseLayout, {
            key: 0,
            isLoading: isLoading.value
          }, null, 8, ["isLoading"]))
        : (_openBlock(), _createBlock(_component_router_view, { key: 1 })),
      (isLoading.value)
        ? (_openBlock(), _createBlock(_component_q_inner_loading, {
            key: 2,
            showing: isLoading.value
          }, {
            default: _withCtx(() => [
              _createVNode(AppLoader)
            ]),
            _: 1
          }, 8, ["showing"]))
        : _createCommentVNode("", true),
      _createVNode(_component_q_footer, {
        class: _normalizeClass(["bg-grey-3 text-primary", {
        'small-text': _ctx.$q.screen.lt.sm,
      }])
      }, {
        default: _withCtx(() => [
          (!_unref(isAuth))
            ? (_openBlock(), _createBlock(_component_q_img, {
                key: 0,
                src: _imports_0,
                class: "full-width"
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_q_toolbar, { class: "base-footer column" }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_q_btn, {
                    "no-caps": "",
                    flat: "",
                    dense: "",
                    class: _normalizeClass(["q-py-none", {
                'small-text': _ctx.$q.screen.lt.sm,
              }]),
                    icon: "call",
                    label: "+7 (812) 325-03-05",
                    href: "tel:+78123250305"
                  }, null, 8, ["class"]),
                  _createVNode(_component_q_btn, {
                    "no-caps": "",
                    flat: "",
                    dense: "",
                    class: _normalizeClass(["q-py-none", {
                'small-text': _ctx.$q.screen.lt.sm,
              }]),
                    icon: "email",
                    label: "info@ecosafety.ru",
                    href: "mailto:info@ecosafety.ru"
                  }, null, 8, ["class"])
                ]),
                _cache[7] || (_cache[7] = _createElementVNode("div", {
                  style: {"padding":"0 5px"},
                  class: "text-center"
                }, " 191119, Санкт-Петербург, ул. Достоевского, 40-44 ", -1))
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_q_space),
                _createElementVNode("div", _hoisted_3, " Группа компаний Эко-безопасность © 2011-" + _toDisplayString(new Date().getFullYear()), 1)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_q_dialog, {
        modelValue: _unref(needChange),
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(needChange) ? (needChange).value = $event : null)),
        persistent: "",
        "transition-show": "scale",
        "transition-hide": "scale",
        class: "first-acquaintance"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card, {
            class: "bg-warning text-blue-grey-10",
            style: {"width":"300px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, { class: "q-pa-sm" }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createElementVNode("div", { class: "text-h6 text-center" }, "Измените пароль!", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_q_form, {
                onSubmit: _withModifiers(tryChangePass, ["prevent"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_input, {
                        dense: "",
                        filled: "",
                        type: isPwd.value ? 'password' : 'text',
                        modelValue: data.newPpassword,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((data.newPpassword) = $event)),
                        label: "Новый пароль",
                        "lazy-rules": "",
                        class: "q-mt-xs",
                        "bg-color": "white",
                        rules: [
                  val => val !== null && val !== '' || 'Обязательное поле',
                ]
                      }, {
                        append: _withCtx(() => [
                          _createVNode(_component_q_icon, {
                            name: isPwd.value ? 'visibility_off' : 'visibility',
                            class: "cursor-pointer",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (isPwd.value = !isPwd.value))
                          }, null, 8, ["name"])
                        ]),
                        _: 1
                      }, 8, ["type", "modelValue", "rules"]),
                      _createVNode(_component_q_input, {
                        dense: "",
                        filled: "",
                        type: isPwdRepeat.value ? 'password' : 'text',
                        modelValue: data.newPpasswordRepeat,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((data.newPpasswordRepeat) = $event)),
                        label: "Еще раз",
                        "lazy-rules": "",
                        class: "q-mt-xs",
                        "bg-color": "white",
                        rules: [
                  val => val !== null && val !== '' || 'Обязательное поле',
                ]
                      }, {
                        append: _withCtx(() => [
                          _createVNode(_component_q_icon, {
                            name: isPwdRepeat.value ? 'visibility_off' : 'visibility',
                            class: "cursor-pointer",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (isPwdRepeat.value = !isPwdRepeat.value))
                          }, null, 8, ["name"])
                        ]),
                        _: 1
                      }, 8, ["type", "modelValue", "rules"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_card_actions, {
                    align: "right",
                    class: "bg-white text-blue-grey-10"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_btn, {
                        flat: "",
                        icon: "logout",
                        label: "Выйти",
                        onClick: _withModifiers(logout, ["prevent"])
                      }),
                      _createVNode(_component_q_btn, {
                        glossy: "",
                        push: "",
                        icon: "save",
                        label: "Сохранить",
                        type: "submit"
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_q_dialog, {
        modelValue: _unref(modal),
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(modal) ? (modal).value = $event : null)),
        maximized: _unref(maximized),
        "full-height": _unref(maximized),
        "full-width": _unref(maximized),
        class: _normalizeClass({
        'prepare-report': _unref(reportMode),
      })
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_Suspense, null, {
            default: _withCtx(() => [
              _createVNode(ModalContainer, { onClose: closeModal })
            ]),
            _: 1
          }))
        ]),
        _: 1
      }, 8, ["modelValue", "maximized", "full-height", "full-width", "class"]),
      _createVNode(_component_q_dialog, {
        modelValue: messageShown.value,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((messageShown).value = $event)),
        seamless: "",
        position: "bottom"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card, { class: "row message-card bg-amber-6 text-black" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, { class: "row items-center no-wrap" }, {
                default: _withCtx(() => [
                  _createElementVNode("pre", _hoisted_4, _toDisplayString(_unref(messageContent)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}
}

})