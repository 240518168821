<template>
  <div>
    <!-- <p class="row"
    >
      <span
        class='q-mr-xs'
        v-for="(i, index) in text"
        :key="index">
        {{ i[nameField] }}
      </span>
    </p> -->
    <TableComponent
      v-if="text?.length"
      :data="text"
      :structure="structure"
    />
  </div>
</template>

<script setup lang="ts">
import {
  onMounted,
  PropType,
  computed,
} from 'vue';
import { Action, ActionParams } from './types';

const props = defineProps({
  text: Array as PropType<any>,
  fields: Array as PropType<any>,
  action: Object as PropType<Action>,
  actionParams: Object as PropType<ActionParams>,
});

const nameField = computed(() => {
  if (props.fields) {
    const jsonField = props.fields[0].name_column.split('.')[0];
    if (jsonField) {
      return jsonField.substring(0, jsonField.length - 1);
    }
  }
  return '';
});

// eslint-disable-next-line arrow-body-style
const structure = computed(() => {
  // eslint-disable-next-line arrow-body-style
  const innerFields = props.fields.map((i: any) => {
    // eslint-disable-next-line arrow-body-style
    // eslint-disable-next-line no-param-reassign
    const field = { ...i };
    [field.parent, field.name_column] = i.name_column.split('.');
    return field;
  });
  return {
    hide_head: true,
    direction: 'row',
    expandable_rows: false,
    pagination: false,
    title: '',
    field_id: nameField.value,
    columns: innerFields,
  };
});
const emit = defineEmits(['action']);

const clickHandler = () => {
  const { action } = props;
  if (!action) {
    return;
  }
  const params = {
    actionId: action.name,
    actionType: action.type,
    params: props.actionParams,
  };
  emit('action', params);
};
</script>

<style lang="scss" scoped>
  .clickable {
    color: $accent;
    text-decoration: underline;
    cursor: pointer;
  }
</style>
