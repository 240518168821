<template>
  <div class="expandable-content">
    <table v-if="tableData.length">
      <tbody>
        <tr v-for="(item, index) in tableData" :key="index">
          <td>
            {{columns.find((col) => col.name === item[0])?.readableName}}
            <q-btn flat rounded @click="emit('unhideColumn', item[0]);" >
              <q-icon name="visibility" />
            </q-btn>
          </td>
          <td>{{item[1]}}</td>
        </tr>
      </tbody>
    </table>
    <div v-if="additional.length">
      <component
        v-for="(component, index) in additional"
        :key="index"
        :is="defineAdditionalComponent(component)"
        :value="component.value"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ExpandableContent, TableRow } from '@/components/TableComponent/types';
import {
  computed,
  PropType,
} from 'vue';
import CustomButton from './CustomButton.vue';

type OnlyNamesColumn = {
  name: string,
  readableName: string,
}

const contentTypes = {
  button: CustomButton,
};

const props = defineProps({
  data: {
    type: Object as PropType<TableRow>,
    default: () => {},
  },
  columns: {
    type: Array as PropType<OnlyNamesColumn[]>,
    default: () => [],
  },
  additional: {
    type: Array as PropType<ExpandableContent[]>,
    default: () => [],
  },
});

const emit = defineEmits(['unhideColumn']);

const tableData = computed(() => Object.entries(props.data)
  .filter((item: any) => props.columns
    .some((col) => col.name === item[0])));

const defineAdditionalComponent = (component: ExpandableContent) => contentTypes[component.type];

</script>

<style lang="scss" scoped>
  .expandable-content {
    display: flex;
    justify-content: space-between;
  }
</style>
