import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, unref as _unref, mergeProps as _mergeProps, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "table__header" }
const _hoisted_2 = { class: "text-h6" }
const _hoisted_3 = { class: "table__wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "sticky-head"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["colspan"]
const _hoisted_11 = {
  key: 0,
  class: "table__footer"
}

import {
  PropType, computed, reactive, defineAsyncComponent, onBeforeMount,
  onActivated, onDeactivated,
} from 'vue';
import { actionHandler } from '~actions/actionHandler';
import {
  TableColumn, TableRow, TableStructure, Filter,
} from './types';
import SimpleCell from './SimpleCell.vue';
import EditableCell from './EditableCell.vue';
import ExpandableCell from './ExpandableCell.vue';
import CustomButton from './CustomButton.vue';
import ColumnFilter from './ColumnFilter.vue';
import ExpandableContent from './ExpandableContent.vue';
import { convertDataForSorting } from './helpers';
import JsonStringCell from './JsonStringCell.vue';

enum SortDirection {
  asc = 1, desc = -1, nd = 0,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TableComponent',
  props: {
  data: {
    type: Array as PropType<TableRow[]>,
    default: () => [],
  },
  structure: {
    type: Object as PropType<TableStructure>,
    default: () => {},
  },
},
  setup(__props) {

const cellsTypeMap = {
  expanded: ExpandableCell,
  json: SimpleCell,
  json_string: JsonStringCell,
  custom: null,
  button: CustomButton,
  editable: EditableCell,
  default: SimpleCell,
};

const paginationOptions: number[] = [5, 10, 20, 50];

const props = __props;

const data = reactive({
  items: props.data,
  field_id: props.structure.field_id,
  columns: props.structure.columns,
  direction: SortDirection.nd,
  selectedItems: [] as number[],
  selectAll: false,
  isLoading: false,
  filters: [] as Filter[],
  currentPage: 1,
  expandedRow: null as number | null,
  itemsPerPage: 20,
});

const generateIds = () => {
  if (!props.structure.field_id) {
    data.field_id = 'id';
    data.items.forEach((item, index) => {
      // eslint-disable-next-line no-param-reassign
      item.id = index;
    });
  }
};

onBeforeMount(() => { generateIds(); });

// onDeactivated(() => {
//   console.log(data.selectedItems);
//   localStorage.setItem('selectedItems', JSON.stringify(data.selectedItems));
// });

// onActivated(() => {
//   console.log(data.selectedItems);
//   if (localStorage.getItem('selectedItems')) {
//     data.selectedItems = JSON.parse(localStorage.getItem('selectedItems') || '');
//   }
// });

const defineComponentName = (column: TableColumn) => {
  if (column.type === 'custom' && column.custom_component_name) {
    return defineAsyncComponent(() => import(`@/components/TableComponent/${column.custom_component_name}.vue`));
  }
  return cellsTypeMap[column.content_type];
};

const defineCellProps = (column: TableColumn, content: keyof TableRow) => {
  let text;
  if (column.content_type === 'json_string' && content) {
    text = JSON.parse(content.toString());
    const fields = data.columns.filter((i) => i.name_column.includes(`${column.name_column}.`)) ?? [];
    return {
      ...column,
      fields,
      text,
    };
  }
  if (typeof content === 'number') {
    text = content.toString();
  } else {
    text = content;
  }
  return {
    ...column,
    text,
  };
};

const visibleColumns = computed(() => data.columns
  .filter((el) => el.visible && !el.could_hide));

const hiddenColumns = computed(() => data.columns
  .filter((el) => el.could_hide)
  .map((col) => ({
    name: col.name, readableName: col.name_column,
  })));

const unhideColumn = (name: string) => {
  const column = data.columns.find((col) => col.name === name);
  if (column) {
    column.could_hide = false;
  }
};

const expandableAreaSize = computed(() => {
  const coeff = props.structure.selection ? 1 : 0;
  return visibleColumns.value.length + coeff;
});

const filteredItems = computed((): TableRow[] => {
  let filtered = data.items;
  data.filters.forEach((el) => {
    filtered = filtered.filter((item) => {
      if (typeof el.value === 'string') {
        return el.value === item[el.colName];
      }
      return el.value?.includes(item[el.colName]);
    });
  });
  return filtered;
});

const sort = (column: TableColumn) => {
  switch (data.direction) {
    case SortDirection.nd:
      data.direction = SortDirection.asc;
      break;
    case SortDirection.asc:
      data.direction = SortDirection.desc;
      break;
    case SortDirection.desc:
      data.direction = SortDirection.asc;
      break;
    default:
      data.direction = SortDirection.nd;
  }

  data.items.sort((a, b) => {
    let result = 0;

    const aData: string | number = convertDataForSorting(a[column.name]);
    const bData: string | number = convertDataForSorting(b[column.name]);
    if (aData > bData) {
      result = 1;
    } else if (bData > aData) {
      result = -1;
    }
    return result * data.direction;
  });
  data.columns.forEach((col) => {
    // eslint-disable-next-line no-param-reassign
    col.sorted = undefined;
  });
  // eslint-disable-next-line no-param-reassign
  column.sorted = SortDirection[data.direction];
};

const setColumnValues = (colName: string): string[] | undefined => {
  const values: string[] = [];
  data.items.forEach((item) => {
    if (!values.includes(item[colName])) {
      values.push(item[colName]);
    }
  });
  return values;
};

const setFilter = (filter: Filter) => {
  const existedFilterIndex = data.filters.findIndex((el) => el.colName === filter.colName);
  if (existedFilterIndex === -1) {
    data.filters.push(filter);
    return;
  }
  if (!filter.value) {
    data.filters.splice(existedFilterIndex, 1);
    return;
  }
  const existedFilter = data.filters[existedFilterIndex];
  if (existedFilter && existedFilter.value) {
    if (filter.type === 'multiple') {
      existedFilter.value = Array.from(new Set(existedFilter.value.concat(filter.value)));
    } else {
      existedFilter.value = filter.value;
    }
  }
};

const handleSelect = (val: number[]) => {
  if (props.structure.selection !== 'single'
    || !data.selectedItems.length) {
    return;
  }
  data.selectedItems = [];
  data.selectedItems.push(val[val.length - 1]);
};

const handleSelectAll = (val: boolean) => {
  if (!val) {
    data.selectedItems = [];
    return;
  }
  data.selectedItems = data.items.map((item) => item[data.field_id]);
};

const paginatedItems = computed(() => {
  const { pagination } = props.structure;
  if (!pagination) {
    return filteredItems.value;
  }
  return filteredItems.value
    .slice((data.currentPage - 1) * data.itemsPerPage, data.currentPage * data.itemsPerPage);
});

const maxPages = computed(() => Math.ceil(filteredItems.value.length / data.itemsPerPage));

const paginate = (pageNumber: number) => {
  data.currentPage = pageNumber;
};

const expandRow = (id: number) => {
  if (data.expandedRow === id) {
    data.expandedRow = null;
    return;
  }
  data.expandedRow = id;
};


return (_ctx: any,_cache: any) => {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_markup_table = _resolveComponent("q-markup-table")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_pagination = _resolveComponent("q-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(__props.structure.title), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_q_markup_table, { dense: "" }, {
        default: _withCtx(() => [
          (!__props.structure.hide_head)
            ? (_openBlock(), _createElementBlock("thead", _hoisted_4, [
                _createElementVNode("tr", null, [
                  (__props.structure.selection)
                    ? (_openBlock(), _createElementBlock("th", _hoisted_5, [
                        (__props.structure.selection === 'multiple')
                          ? (_openBlock(), _createBlock(_component_q_checkbox, {
                              key: 0,
                              modelValue: data.selectAll,
                              "onUpdate:modelValue": [
                                _cache[0] || (_cache[0] = ($event: any) => ((data.selectAll) = $event)),
                                handleSelectAll
                              ]
                            }, null, 8, ["modelValue"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleColumns.value, (column, index) => {
                    return (_openBlock(), _createElementBlock("th", {
                      key: index,
                      class: _normalizeClass([{ sortable: column.sortable }])
                    }, [
                      (column.sorted === 'asc')
                        ? (_openBlock(), _createBlock(_component_q_icon, {
                            key: 0,
                            name: "expand_more"
                          }))
                        : _createCommentVNode("", true),
                      (column.sorted === 'desc')
                        ? (_openBlock(), _createBlock(_component_q_icon, {
                            key: 1,
                            name: "expand_less"
                          }))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", {
                        class: "colname colname_sortable",
                        onClick: ($event: any) => (column.sortable ? sort(column) : '')
                      }, _toDisplayString(column.alias), 9, _hoisted_6),
                      (column.filterable)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 2,
                            class: _normalizeClass({active: data.filters.find(
                (filter) => filter.colName === column.name_column)
              }),
                            flat: "",
                            round: "",
                            icon: "filter_list"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_menu, null, {
                                default: _withCtx(() => [
                                  _createVNode(ColumnFilter, {
                                    initial: data.filters.find((filter) => filter.colName === column.name_column),
                                    multiple: column.filterable === 'multiple',
                                    colName: column.name_column,
                                    items: setColumnValues(column.name_column),
                                    label: column.alias,
                                    onSetFilter: setFilter
                                  }, null, 8, ["initial", "multiple", "colName", "items", "label"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["class"]))
                        : _createCommentVNode("", true),
                      (column.could_hide && __props.structure.expandable_rows)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 3,
                            flat: "",
                            rounded: "",
                            onClick: ($event: any) => (column.could_hide = true)
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_icon, { name: "visibility_off" })
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        : _createCommentVNode("", true)
                    ], 2))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("tbody", {
            style: _normalizeStyle(__props.structure.direction ? "display: flex;" : "")
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(paginatedItems.value, (row) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: row[data.field_id]
              }, [
                _createElementVNode("tr", {
                  style: _normalizeStyle(row.style_row ? JSON.parse(row.style_row)[0] : ""),
                  class: _normalizeClass({ clickable: __props.structure.expandable_rows }),
                  onClick: ($event: any) => (expandRow(row[data.field_id]))
                }, [
                  (__props.structure.selection)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_8, [
                        _createVNode(_component_q_checkbox, {
                          val: row[data.field_id],
                          modelValue: data.selectedItems,
                          "onUpdate:modelValue": [
                            _cache[1] || (_cache[1] = ($event: any) => ((data.selectedItems) = $event)),
                            handleSelect
                          ]
                        }, null, 8, ["val", "modelValue"])
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleColumns.value, (_, index) => {
                    return (_openBlock(), _createElementBlock("td", { key: index }, [
                      _renderSlot(_ctx.$slots, "default", {}, () => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(defineComponentName(visibleColumns.value[index])), _mergeProps({ ref_for: true }, defineCellProps(
                    visibleColumns.value[index], row[visibleColumns.value[index].name_column]
                  ), {
                          actionParams: row,
                          onAction: _unref(actionHandler)
                        }), null, 16, ["actionParams", "onAction"]))
                      ])
                    ]))
                  }), 128))
                ], 14, _hoisted_7),
                (__props.structure.expandable_rows && row[data.field_id] === data.expandedRow)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_9, [
                      _createElementVNode("td", { colspan: expandableAreaSize.value }, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(ExpandableContent), {
                          data: row,
                          columns: hiddenColumns.value,
                          additional: __props.structure.expandable_content,
                          onUnhideColumn: unhideColumn
                        }, null, 40, ["data", "columns", "additional"]))
                      ], 8, _hoisted_10)
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ], 4)
        ]),
        _: 3
      })
    ]),
    (__props.structure.pagination)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "pagination-description" }, "Показывать по:", -1)),
          _createVNode(_component_q_select, {
            outlined: "",
            dense: "",
            modelValue: data.itemsPerPage,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((data.itemsPerPage) = $event)),
            options: paginationOptions
          }, null, 8, ["modelValue"]),
          _createVNode(_component_q_pagination, {
            max: maxPages.value,
            color: "accent",
            "direction-links": "",
            "boundary-links": "",
            "boundary-numbers": "",
            modelValue: data.currentPage,
            "onUpdate:modelValue": [
              _cache[3] || (_cache[3] = ($event: any) => ((data.currentPage) = $event)),
              paginate
            ]
          }, null, 8, ["max", "modelValue"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})