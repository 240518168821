<template>
  <div :class="{clickable: action}" @click="clickHandler">
    {{ text }}
  </div>
</template>

<script setup lang="ts">
import {
  PropType,
} from 'vue';
import { Action, ActionParams } from './types';

const props = defineProps({
  text: String as PropType<string>,
  action: Object as PropType<Action>,
  actionParams: Object as PropType<ActionParams>,
});

const emit = defineEmits(['action']);

const clickHandler = () => {
  const { action } = props;
  if (!action) {
    return;
  }
  const params = {
    actionId: action.name,
    actionType: action.type,
    params: props.actionParams,
  };
  emit('action', params);
};
</script>

<style lang="scss" scoped>
  .clickable {
    color: $accent;
    text-decoration: underline;
    cursor: pointer;
  }
</style>
