import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "expandable-content" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

import { ExpandableContent, TableRow } from '@/components/TableComponent/types';
import {
  computed,
  PropType,
} from 'vue';
import CustomButton from './CustomButton.vue';

type OnlyNamesColumn = {
  name: string,
  readableName: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpandableContent',
  props: {
  data: {
    type: Object as PropType<TableRow>,
    default: () => {},
  },
  columns: {
    type: Array as PropType<OnlyNamesColumn[]>,
    default: () => [],
  },
  additional: {
    type: Array as PropType<ExpandableContent[]>,
    default: () => [],
  },
},
  emits: ['unhideColumn'],
  setup(__props, { emit: __emit }) {

const contentTypes = {
  button: CustomButton,
};

const props = __props;

const emit = __emit;

const tableData = computed(() => Object.entries(props.data)
  .filter((item: any) => props.columns
    .some((col) => col.name === item[0])));

const defineAdditionalComponent = (component: ExpandableContent) => contentTypes[component.type];


return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (tableData.value.length)
      ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tableData.value, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", null, [
                  _createTextVNode(_toDisplayString(__props.columns.find((col) => col.name === item[0])?.readableName) + " ", 1),
                  _createVNode(_component_q_btn, {
                    flat: "",
                    rounded: "",
                    onClick: ($event: any) => {emit('unhideColumn', item[0]);}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_icon, { name: "visibility" })
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _createElementVNode("td", null, _toDisplayString(item[1]), 1)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (__props.additional.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.additional, (component, index) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(defineAdditionalComponent(component)), {
              key: index,
              value: component.value
            }, null, 8, ["value"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})