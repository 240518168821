<template>
  <div class="column-filter">
    Фильтр по полю {{label}}
    <q-select
      v-if="items && items.length"
      outlined
      clearable
      :multiple="multiple"
      v-model="selectedItem"
      :options="items"
      :label="label"
      @update:model-value="setFilter"
    />
    <span v-else>
      Нет фильтров
    </span>
    <span v-if="multiple">Можно выбрать несколько значений</span>
  </div>
</template>

<script setup lang="ts">
import { Filter } from '@/components/TableComponent/types';
import {
  onBeforeMount, PropType, Ref, ref,
} from 'vue';

type FilterValue = string[] | null;

const props = defineProps({
  initial: Object as PropType<Filter | undefined>,
  colName: String as PropType<string>,
  items: Array as PropType<string[]>,
  label: String as PropType<string>,
  multiple: Boolean as PropType<boolean>,
});

const selectedItem: Ref<FilterValue> = ref(null);

onBeforeMount(() => {
  if (props.initial) {
    selectedItem.value = props.initial.value;
  }
});

const emit = defineEmits([
  'setFilter',
]);

const setFilter = () => {
  if (!props.colName) return;
  let value: string[] | null;
  if (typeof selectedItem.value === 'string') {
    value = [selectedItem.value];
  } else {
    value = selectedItem.value;
  }
  const filter: Filter = { colName: props.colName, value, type: props.multiple ? 'multiple' : 'single' };
  emit('setFilter', filter);
};

</script>

<style lang="scss" scoped>
  .column-filter {
    padding: 10px;
  }
</style>
