import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Suspense as _Suspense, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "q-py-md q-px-xl" }


import {
  computed, defineAsyncComponent, PropType, reactive,
} from 'vue';
import { actionHandler } from '@/actions/actionHandler';
import { Action } from './TableComponent/types';
import AppLoader from './AppLoader.vue';

const __default__ = { name: 'AppButton' };

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
  label: {
    type: String as PropType<string>,
  },
  type: {
    type: String as PropType<string>,
  },
  icon: {
    type: String as PropType<string>,
  },
  disable: {
    type: Boolean as PropType<boolean>,
    default: () => false,
  },
  loading: {
    type: Boolean as PropType<boolean>,
    default: () => false,
  },
  action: {
    type: Object as PropType<Action>,
    required: true,
  },
  context: {
    type: Object as PropType<Record<string, unknown>>,
  },
},
  emits: ['success', 'upload'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const isMenuAnchor = computed(() => props.action.type === 'menu');

const isUploader = computed(() => props.type === 'upload');

// const menuComponent = defineAsyncComponent(
//   () => import(`~components/menus/${props.action.name}.vue`),
// );

const uploadHandler = (event: Event) => {
  const target = event.currentTarget as HTMLInputElement;
  const [file]: File[] = [...(target.files as FileList)];
  const type = props.action.customParam;
  const action = {
    ...props.action,
    customParam: {
      type,
      file,
    },
  };
  actionHandler(action);
};

const updateHandler = (val: any) => {
  console.log('update', val);
};

const onAction = async () => {
  const result = await actionHandler(props.action);
  if (result) {
    emit('success');
  }
};

const handler = computed(() => {
  if (isUploader.value) {
    return updateHandler;
  }
  return onAction;
});

return (_ctx: any,_cache: any) => {
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_spinner_hourglass = _resolveComponent("q-spinner-hourglass")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, _mergeProps(props, {
    push: "",
    "no-caps": "",
    color: "white",
    "text-color": "accent",
    type: __props.type,
    disable: __props.disable,
    loading: __props.loading,
    onClick: handler.value
  }), {
    loading: _withCtx(() => [
      _createVNode(_component_q_spinner_hourglass)
    ]),
    default: _withCtx(() => [
      (isMenuAnchor.value)
        ? (_openBlock(), _createBlock(_component_q_menu, { key: 0 }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_Suspense, null, {
                fallback: _withCtx(() => [
                  _createVNode(_component_q_card, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createVNode(AppLoader)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.menuComponent), {
                    params: props.context || props.action.customParam,
                    onUpdate: updateHandler
                  }, null, 40, ["params"]))
                ]),
                _: 1
              }))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (isUploader.value)
        ? (_openBlock(), _createElementBlock("input", {
            key: 1,
            type: "file",
            onChange: uploadHandler,
            "aria-label": "-/-"
          }, null, 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16, ["type", "disable", "loading", "onClick"]))
}
}

})